<!-- Companies -->
<template>
  <GgPage pageType="2" title="Companies">
    <template v-slot:left>
      <div class="mar-l-b">
        <gg-create-button @click="openDialogAdd(null)" label="Add company" />
      </div>
      <gg-flex-menus
        :menus="menuDatas"
        @select="menuSelect"
        :default-active="activeIndex"
      />
    </template>
    <!-- <GgPageHeader title="Users" class="mar-b-0" /> -->
    <edoovo-table
      height="100%"
      :ref="usersData.ref"
      :data="usersData.tableData"
      rowKey="id"
      title="Companies"
      defaultValue="-"
      class="list-tableBox"
      v-loading="usersData.isLoading"
      @sortChange="_onSortChange"
      :sort="usersData.sort"
    >
      <!-- ===== -->
      <!-- 空状态 -->
      <!-- ===== -->
      <div slot="empty" style="text-align: center">
        <div
          style="
            width: 213px;
            text-align: center;
            border-radius: 50%;
            margin-bottom: 12px;
            line-height: 200px;
          "
        >
          <img
            style="width: 100%"
            :src="require('@/assets/images/products/noData.svg')"
          />
        </div>
        <p style="font-size: 22px">No Company yet.</p>
      </div>
      <!-- ======= -->
      <!-- 分页组件 -->
      <!-- ======= -->
      <edoovo-pagination
        slot="pagination"
        @size-change="_pageSizeChange"
        @current-change="_pageChange"
        :current-page="query.current"
        :page-size="query.size"
        :total="usersData.totalNum"
      />
      <!-- ====== -->
      <!-- 筛选组件 -->
      <!-- ====== -->
      <div v-if="filterRenderData.length" class="list-filterBox" slot="filter">
        <base-filter
          ref="tablefilter"
          @filterCallback="_filterCallback"
          :renderData="filterRenderData"
          :multKeys="multKeys"
        />
      </div>
      <!-- =========== -->
      <!-- 每一项column -->
      <!-- =========== -->
      <edoovo-table-column
        size="M"
        label="Company ID"
        class="user_name"
        prop="companyId"
        sortName="companyId"
      />
      <edoovo-table-column
        size="L"
        label="Company name"
        class="table-cell-box"
        prop="companyName"
        sortName="companyName"
        :tooltip="true"
        v-slot="{ row }"
        @click="toCompanyInfo"
      >
        {{ row.companyName }}
      </edoovo-table-column>
      <!-- this.$router.push({
        name: "tablePage",
        query: { title: item },
      }); -->
      <edoovo-table-column size="M" label="Businesses" prop="businesses" />
      <edoovo-table-column
        size="L"
        label="Locations"
        prop="locations"
        :tooltip="true"
      />
      <edoovo-table-column
        size="M"
        label="Created"
        prop="createTime"
        sortName="createTime"
        v-slot="{ row }"
        :tooltip="true"
		 :getTooltipText="(row) => dateFormat(row.createTime)"
      >
        {{ dateFormat(row.createTime) }}
      </edoovo-table-column>
      <edoovo-table-column size="S" label="Status" prop="status" 
	  v-slot="{ row }"
	  :tooltip="true"
	   :getTooltipText="(row) => `${(row.status?row.status[0].toUpperCase()+row.status.substring(1):'-')}`"
	  >
		  {{(row.status?row.status[0].toUpperCase()+row.status.substring(1):'-')}}
	  </edoovo-table-column>
      <!-- ====== -->
      <!-- action -->
      <!-- ====== -->
      <edoovo-table-column v-slot="{ row }" key="action">
        <el-tooltip
          content="Edit"
          popper-class="edoovo-table-cell-tooltip"
          :visible-arrow="false"
        >
          <gg-submit-button @click="openDialogAdd(row)"  v-if="row.status == 'active'">
            Edit
          </gg-submit-button>
        </el-tooltip>
        <gg-dropdown v-if="row.status == 'active'">
          More
          <template slot="menus">
            <gg-dropdown-item
              v-if="row.status == 'suspended'"
              @click="handleReactive(row)"
            >
              Reactivate
            </gg-dropdown-item>
            <gg-dropdown-item
              v-if="row.status == 'closed'"
              @click="handleRestore(row)"
            >
              Restore
            </gg-dropdown-item>
            <gg-dropdown-item
              v-if="row.status == 'active'"
              @click="handleSuspend(row)"
            >
              Suspend
            </gg-dropdown-item>
            <gg-dropdown-item
              v-if="row.status == 'active'"
              @click="handleClose(row)"
            >
              Close
            </gg-dropdown-item>
          </template>
        </gg-dropdown>
		<gg-submit-button @click="handleReactive(row)"  v-else-if="row.status == 'suspended'">
		  Reactivate
		</gg-submit-button>
    <gg-submit-button @click="handleRestore(row)"  v-else-if="row.status == 'closed'">
		  Restore
		</gg-submit-button>
      </edoovo-table-column>
    </edoovo-table>
    <BaseFormDialog1
      v-model="formDialogAdd.visible"
      :title="formDialogAdd.title"
      :data="formDialogAdd.dataList"
      textReminder="* indicates a required field"
      :renderPass="formDialogAdd.renderPass"
      :ref="formDialogAdd.ref"
    >
      <template slot="renderHtml" slot-scope="scope">
        <div
          v-if="scope.data.key === 'logo'"
          :ref="scope.data.key"
          class="upload-imgbox"
          :placeholder="scope.data.placeholder"
        >
          <div class="upload-img" @click="isImgCutShow = true">
            <head-img
              v-if="formDialogAdd.dataRender.logo"
              :src="formDialogAdd.dataRender.logo"
              line-height="88"
            />
            <i v-else class="material-icons iconfont iconadd_a_photo" />
          </div>
        </div>
        <gg-input
          v-else-if="scope.data.key === 'brandName'"
          :key="scope.data.key"
          :ref="scope.data.key"
          :placeholder="scope.data.placeholder"
          v-model="formDialogAdd.dataRender.brandName"
          :error="formDialogAdd.brandNameErrorObj"
        />
        <baseGGSelect
          v-else-if="scope.data.key === 'businesses'"
          v-model="formDialogAdd.dataRender.businesses"
          :ref="scope.data.key"
          :placeholder="scope.data.placeholder"
          multiple
          md-dense
        >
          <md-option
            v-for="(item, index) in scope.data.itemList"
            :key="index"
            :value="item"
          >
            {{ item }}
          </md-option>
        </baseGGSelect>
        <gg-input
          v-else-if="scope.data.key === 'address'"
          filedType="textarea"
          :key="scope.data.key"
          :ref="scope.data.key"
          :placeholder="scope.data.placeholder"
          v-model="formDialogAdd.dataRender.address"
        />
        <gg-input
          v-else-if="scope.data.key === 'companyName'"
          :key="scope.data.key"
          :ref="scope.data.key"
          :placeholder="scope.data.placeholder"
          v-model="formDialogAdd.dataRender.companyName"
          :error="formDialogAdd.companyNameErrorObj"
        />
        <baseGGSelect
          v-else-if="scope.data.key === 'country'"
          v-model="formDialogAdd.dataRender.country"
          :ref="scope.data.key"
          :placeholder="scope.data.placeholder"
          class="float"
        >
          <md-option
            v-for="(item, index) in scope.data.itemList"
            :key="index"
            :value="item"
          >
            {{ item }}
          </md-option>
        </baseGGSelect>
        <!-- todo -->
        <gg-input
          style="margin-bottom: 40px"
          v-else-if="scope.data.key === 'email'"
          :ref="scope.data.key"
          v-model="formDialogAdd.dataRender.email"
          :placeholder="scope.data.placeholder"
          :error="formDialogAdd.emailErrorObj"
        />
        <PhoneNumber
          v-else-if="scope.data.key === 'phone'"
          v-model="formDialogAdd.dataRender.phone"
          :ref="scope.data.key"
          :placeholder="scope.data.placeholder"
        ></PhoneNumber>
        <div
          v-else-if="scope.data.key === 'organizationCertificate'"
          class="mar-b-20 gg_input"
        >
          <div class="upload-describ mar-b-10">
            {{ scope.data.placeholder }}
          </div>
          <el-upload
            class="upload-wrapper"
            ref="uploadFileInp"
            action=""
            accept="image/jpeg,image/png,application/pdf"
            :auto-upload="false"
            list-type="picture"
            :file-list="scope.data.itemList"
            :on-change="uploadFileChange"
          >
            <template
              v-if="formDialogAdd.dataRender.organizationCertificate"
              slot="file"
              slot-scope="{ file }"
            >
              <i v-if="file.type === 'pdf'" class="el-icon-document "></i>
              <img
                v-else
                class="upload-img"
                :src="formDialogAdd.dataRender.organizationCertificate"
                alt=""
                @click="handlePreview(file)"
              />
              <p class="upload-txt" @click="handlePreview(file)">
                {{ `File name : ${file.name}` }}
              </p>
              <i class="el-icon-close" @click="handleRemove"></i>
            </template>
            <div
              v-if="!formDialogAdd.dataRender.organizationCertificate"
              class="upload-btn"
            >
              Add file
            </div>
          </el-upload>
        </div>
      </template>
      <template slot="footer">
        <baseButton @click="handleCancelForm">Cancel</baseButton>
        <baseButton
          type="primary"
          :disabled="!formDialogAdd.isDisabled"
          @click="handleAddForm"
          >{{ formDialogAdd.submitBtnTxt }}</baseButton
        >
      </template>
    </BaseFormDialog1>
	<!-- 0712cy留新增框 -->
	<BaseFormDialog1
	  v-model="formDialogAdd1.visible"
	  :title="formDialogAdd1.title"
	  :data="formDialogAdd1.dataList"
	  textReminder="* indicates a required field"
	  :renderPass="formDialogAdd1.renderPass"
	  :ref="formDialogAdd1.ref"
	>
	  <template slot="renderHtml" slot-scope="scope">
	    <div
	      v-if="scope.data.key === 'logo'"
	      :ref="scope.data.key"
	      class="upload-imgbox"
	      :placeholder="scope.data.placeholder"
	    >
	      <div class="upload-img" @click="isImgCutShow = true">
	        <head-img
	          v-if="formDialogAdd1.dataRender.logo"
	          :src="formDialogAdd1.dataRender.logo"
	          line-height="88"
	        />
	        <i v-else class="material-icons iconfont iconadd_a_photo" />
	      </div>
	    </div>
	    <gg-input
	      v-else-if="scope.data.key === 'brandName'"
	      :key="scope.data.key"
	      :ref="scope.data.key"
	      :placeholder="scope.data.placeholder"
	      v-model="formDialogAdd1.dataRender.brandName"
	      :error="formDialogAdd1.brandNameErrorObj"
	    />
	    <baseGGSelect
	      v-else-if="scope.data.key === 'businesses'"
	      v-model="formDialogAdd1.dataRender.businesses"
	      :ref="scope.data.key"
	      :placeholder="scope.data.placeholder"
	      multiple
	      md-dense
	    >
	      <md-option
	        v-for="(item, index) in scope.data.itemList"
	        :key="index"
	        :value="item"
	      >
	        {{ item }}
	      </md-option>
	    </baseGGSelect>
	    <gg-input
	      v-else-if="scope.data.key === 'address'"
	      filedType="textarea"
	      :key="scope.data.key"
	      :ref="scope.data.key"
	      :placeholder="scope.data.placeholder"
	      v-model="formDialogAdd1.dataRender.address"
	    />
	    <gg-input
	      v-else-if="scope.data.key === 'companyName'"
	      :key="scope.data.key"
	      :ref="scope.data.key"
	      :placeholder="scope.data.placeholder"
	      v-model="formDialogAdd1.dataRender.companyName"
	      :error="formDialogAdd1.companyNameErrorObj"
	    />
	    <baseGGSelect
	      v-else-if="scope.data.key === 'country'"
	      v-model="formDialogAdd1.dataRender.country"
	      :ref="scope.data.key"
	      :placeholder="scope.data.placeholder"
	      class="float"
	    >
	      <md-option
	        v-for="(item, index) in scope.data.itemList"
	        :key="index"
	        :value="item"
	      >
	        {{ item }}
	      </md-option>
	    </baseGGSelect>
	    <!-- todo -->
	
	 
	    <div
	      v-else-if="scope.data.key === 'organizationCertificate'"
	      class="mar-b-20 gg_input"
	    >
	      <div class="upload-describ mar-b-10">
	        {{ scope.data.placeholder }}
	      </div>
	      <el-upload
	        class="upload-wrapper"
	        ref="uploadFileInp"
	        action=""
	        accept="image/jpeg,image/png,application/pdf"
	        :auto-upload="false"
	        list-type="picture"
	        :file-list="scope.data.itemList"
	        :on-change="uploadFileChange1"
	      >
	        <template
	          v-if="formDialogAdd1.dataRender.organizationCertificate"
	          slot="file"
	          slot-scope="{ file }"
	        >
	          <i v-if="file.type === 'pdf'" class="el-icon-document "></i>
	          <img
	            v-else
	            class="upload-img"
	            :src="formDialogAdd1.dataRender.organizationCertificate"
	            alt=""
	            @click="handlePreview(file)"
	          />
	          <p class="upload-txt" @click="handlePreview(file)">
	            {{ `File name : ${file.name}` }}
	          </p>
	          <i class="el-icon-close" @click="handleRemove"></i>
	        </template>
	        <div
	          v-if="!formDialogAdd1.dataRender.organizationCertificate"
	          class="upload-btn"
	        >
	          Add file
	        </div>
	      </el-upload>
	    </div>
	  </template>
	  <template slot="footer">
	    <baseButton @click="handleCancelForm1">Cancel</baseButton>
	    <baseButton
	      type="primary"
	      :disabled="!formDialogAdd1.isDisabled"
	      @click="handleAddForm1"
	      >{{ formDialogAdd1.submitBtnTxt }}</baseButton
	    >
	  </template>
	</BaseFormDialog1>
	<!-- 0713cy next后弹框 -->
	<BaseFormDialog1
	  v-model="formDialogAdd2.visible"
	  :title="formDialogAdd2.title"
	  :data="formDialogAdd2.dataList"
	  textReminder="* indicates a required field"
	  :renderPass="formDialogAdd2.renderPass"
	  :ref="formDialogAdd2.ref"
	>
	  <template slot="renderHtml" slot-scope="scope">
	    <div
	      v-if="scope.data.key === 'photo'"
	      :ref="scope.data.key"
	      class="upload-imgbox"
	      :placeholder="scope.data.placeholder"
	    >
	      <div class="upload-img" @click="isImgCutShow1 = true">
	        <head-img
	          v-if="formDialogAdd2.dataRender.photo"
	          :src="formDialogAdd2.dataRender.photo"
	          line-height="88"
	        />
	        <i v-else class="material-icons iconfont iconadd_a_photo" />
	      </div>
	    </div>
		<gg-input
		  v-else-if="scope.data.key === 'firstname'"
		  :key="scope.data.key"
		  :ref="scope.data.key"
		  :placeholder="scope.data.placeholder"
		  v-model="formDialogAdd2.dataRender.firstname"
		  :error="formDialogAdd2.firstnameErrorObj"
		/>
		<gg-input
		  v-else-if="scope.data.key === 'lastname'"
		  :key="scope.data.key"
		  :ref="scope.data.key"
		  :placeholder="scope.data.placeholder"
		  v-model="formDialogAdd2.dataRender.lastname"
		  :error="formDialogAdd2.lastnameErrorObj"
		/>
	    
	    
	    
	    
	    <!-- todo -->
	    <gg-input
	      style="margin-bottom: 40px"
	      v-else-if="scope.data.key === 'email'"
	      :ref="scope.data.key"
	      v-model="formDialogAdd2.dataRender.email"
	      :placeholder="scope.data.placeholder"
	      :error="formDialogAdd2.emailErrorObj"
	    />
	    <PhoneNumber
	      v-else-if="scope.data.key === 'phone'"
	      v-model="formDialogAdd2.dataRender.phone"
	      :ref="scope.data.key"
	      :placeholder="scope.data.placeholder"
	    ></PhoneNumber>
	    
	      </el-upload>
	    </div>
	  </template>
	  <template slot="footer">
	    <baseButton @click="handleCancelForm2">Cancel</baseButton>
	    <baseButton
	      type="primary"
	      :disabled="!formDialogAdd2.isDisabled"
	      @click="handleAddForm2"
	      >Add</baseButton
	    >
	  </template>
	</BaseFormDialog1>
    <imgCutModal
      :visible.sync="isImgCutShow"
      v-if="isImgCutShow"
      @_uploadCallback="_uploadCallback"
    />
	<!-- 用户头像 -->
	<imgCutModal
	  :visible.sync="isImgCutShow1"
	  v-if="isImgCutShow1"
	  @_uploadCallback="_uploadCallback1"
	/>
    <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    />
  </GgPage>
</template>

<script>
import { Ajax } from "@/common";
import { mapState } from "vuex";
import moment from "moment";
import { lightFormat } from 'date-fns';
export default {
  name: "companies",
  components: {},
  data() {
    return {
      query: {
        size: 20,
        current: 1,
        status: "",
      },
	  firstData:{},
	  secondData:{},
      menuDatas: [
        {
          name: "Companies",
          key: "",
          active: true,
          children: [
            {
              name: "Active",
              key: "active",
            },
            {
              name: "Suspended",
              key: "suspended",
            },
            {
              name: "Closed",
              key: "closed",
            },
          ],
        },
      ],
      activeIndex: "",
      usersData: {
        ref: "usersTable",
        isLoading: false,
        sort: { name: "", type: "" },
        tableData: [],
      },
      filterRenderData: [
        { filterKey: "companyName", type: "input", title: "Company name" },
        {
          filterKey: "businesses",
          type: "checkbox",
          title: "Business(s)",
          checkboxData: [],
        },
        {
          filterKey: "created",
          type: "date",
          title: "Created",
        },
      ],
      multKeys: [],
      isImgCutShow: false,
	  isImgCutShow1: false,
      formDialogAdd: {
        ref: "formDialogAdd",
        visible: false,
        title: "",
        add: "Add new company",
        edit: "Edit company",
        submitBtnTxt: "Add",
        renderPass: false,
        slotData: ["brandName", "companyName", "country"],
        isDisabled: true,
        brandNameErrorObj: {
          show: false,
          message: "",
        },
        companyNameErrorObj: {
          show: false,
          message: "",
        },
        emailErrorObj: {
          show: false,
          message: "",
        },
        dataRender: {
          logo: "",
          brandName: "",
          businesses: [],
          address: "",
          companyName: "",
          country: "",
          email: "",
          phone: "",
          organizationCertificate: "",
        },
        dataList: [
          {
            placeholder: "logo",
            key: "logo",
            value: "",
            renderHtml: true,
          },
          {
            placeholder: "Brand name *",
            key: "brandName",
            value: "",
            renderHtml: true,
          },
          {
            placeholder: "Businesses *",
            key: "businesses",
            value: [],
            itemList: [],
            renderHtml: true,
          },
          {
            placeholder: "Company name *",
            key: "companyName",
            value: "",
            renderHtml: true,
          },
          {
            placeholder: "Address *",
            key: "address",
            value: "",
            renderHtml: true,
          },
          {
            placeholder: "Address line 2",
            key: "addressLine2",
            value: "",
          },
          {
            placeholder: "Postal code *",
            key: "postalCode",
            value: "",
            float: true,
            reg: /[\S]/,
            errorText: "Postal code is required",
          },
          {
            placeholder: "Country *",
            key: "country",
            value: "",
            float: true,
            itemList: [],
            renderHtml: true,
          },
          // {
          //   placeholder: "Email *",
          //   key: "email",
          //   value: "",
          //   renderHtml: true,
          // },
          // todo
          // {
          //   placeholder: "Phone number *",
          //   key: "phone",
          //   value: {
          //     countryCode: "+65",
          //     phoneNumber: "",
          //   },
          //   renderHtml: true,
          // },
          {
            placeholder: "Organization certificate",
            key: "organizationCertificate",
            itemList: [],
            value: "",
            isUpload: true,
            renderHtml: true,
          },
        ],
      },
      snackbarData: {
        visible: false,
        content: "",
      },
	  query: {
	    size: 20,
	    current: 1,
	    status: "",
	  },
	  menuDatas: [
	    {
	      name: "Companies",
	      key: "",
	      active: true,
	      children: [
	        {
	          name: "Active",
	          key: "active",
	        },
	        {
	          name: "Suspended",
	          key: "suspended",
	        },
	        {
	          name: "Closed",
	          key: "closed",
	        },
	      ],
	    },
	  ],
	  activeIndex: "",
	  usersData: {
	    ref: "usersTable",
	    isLoading: false,
	    sort: { name: "", type: "" },
	    tableData: [],
	  },
	  filterRenderData: [
	    { filterKey: "companyName", type: "input", title: "Company name" },
	    {
	      filterKey: "businesses",
	      type: "checkbox",
	      title: "Business(s)",
	      checkboxData: [],
	    },
	    {
	      filterKey: "created",
	      type: "date",
	      title: "Created",
	    },
	  ],
	  multKeys: [],
	  isImgCutShow: false,
	  
	  formDialogAdd1: { //新流程 0712 cy留
	    ref: "formDialogAdd1",
	    visible: false,
	    title: "Add new company",
	    add: "Add new company",
	    edit: "Edit company",
	    submitBtnTxt: "Add",
	    renderPass: false,
	    slotData: ["brandName", "companyName", "country"],
	    isDisabled: true,
	    brandNameErrorObj: {
	      show: false,
	      message: "",
	    },
	    companyNameErrorObj: {
	      show: false,
	      message: "",
	    },
	    emailErrorObj: {
	      show: false,
	      message: "",
	    },
	    dataRender: {
	      logo: "",
	      brandName: "",
	      businesses: [],
	      address: "",
	      companyName: "",
	      country: "",
	      organizationCertificate: "",
	    },
	    dataList: [
	      {
	        placeholder: "logo",
	        key: "logo",
	        value: "",
	        renderHtml: true,
	      },
	      {
	        placeholder: "Brand name *",
	        key: "brandName",
	        value: "",
	        renderHtml: true,
	      },
	      {
	        placeholder: "Businesses *",
	        key: "businesses",
	        value: [],
	        itemList: [],
	        renderHtml: true,
	      },
	      {
	        placeholder: "Company name *",
	        key: "companyName",
	        value: "",
	        renderHtml: true,
	      },
	      {
	        placeholder: "Address *",
	        key: "address",
	        value: "",
	        renderHtml: true,
	      },
	      {
	        placeholder: "Address line 2",
	        key: "addressLine2",
	        value: "",
	      },
	      {
	        placeholder: "Postal code *",
	        key: "postalCode",
	        value: "",
	        float: true,
	        reg: /[\S]/,
	        errorText: "Postal code is required",
	      },
	      {
	        placeholder: "Country *",
	        key: "country",
	        value: "",
	        float: true,
	        itemList: [],
	        renderHtml: true,
	      },
	      // {
	      //   placeholder: "Email *",
	      //   key: "email",
	      //   value: "",
	      //   renderHtml: true,
	      // },
	      // todo
	      // {
	      //   placeholder: "Phone number *",
	      //   key: "phone",
	      //   value: {
	      //     countryCode: "+65",
	      //     phoneNumber: "",
	      //   },
	      //   renderHtml: true,
	      // },
	      {
	        placeholder: "Organization certificate",
	        key: "organizationCertificate",
	        itemList: [],
	        value: "",
	        isUpload: true,
	        renderHtml: true,
	      },
	    ],
	  },
	  snackbarData: {
	    visible: false,
	    content: "",
	  },
	  query: {
	    size: 20,
	    current: 1,
	    status: "",
	  },
	  firstData:{},
	  secondData:{},
	  menuDatas: [
	    {
	      name: "Companies",
	      key: "",
	      active: true,
	      children: [
	        {
	          name: "Active",
	          key: "active",
	        },
	        {
	          name: "Suspended",
	          key: "suspended",
	        },
	        {
	          name: "Closed",
	          key: "closed",
	        },
	      ],
	    },
	  ],
	  activeIndex: "",
	  usersData: {
	    ref: "usersTable",
	    isLoading: false,
	    sort: { name: "", type: "" },
	    tableData: [],
	  },
	  filterRenderData: [
	    { filterKey: "companyName", type: "input", title: "Company name" },
	    {
	      filterKey: "businesses",
	      type: "checkbox",
	      title: "Business(s)",
	      checkboxData: [],
	    },
	    {
	      filterKey: "created",
	      type: "date",
	      title: "Created",
	    },
	  ],
	  multKeys: [],
	  isImgCutShow: false,
	  formDialogAdd: {
	    ref: "formDialogAdd",
	    visible: false,
	    title: "",
	    add: "Add new company",
	    edit: "Edit company",
	    submitBtnTxt: "Add",
	    renderPass: false,
	    slotData: ["brandName", "companyName", "country"],
	    isDisabled: true,
	    brandNameErrorObj: {
	      show: false,
	      message: "",
	    },
	    companyNameErrorObj: {
	      show: false,
	      message: "",
	    },
	    emailErrorObj: {
	      show: false,
	      message: "",
	    },
	    dataRender: {
	      logo: "",
	      brandName: "",
	      businesses: [],
	      address: "",
	      companyName: "",
	      country: "",
	      email: "",
	      phone: "",
	      organizationCertificate: "",
	    },
	    dataList: [
	      {
	        placeholder: "logo",
	        key: "logo",
	        value: "",
	        renderHtml: true,
	      },
	      {
	        placeholder: "Brand name *",
	        key: "brandName",
	        value: "",
	        renderHtml: true,
	      },
	      {
	        placeholder: "Businesses *",
	        key: "businesses",
	        value: [],
	        itemList: [],
	        renderHtml: true,
	      },
	      {
	        placeholder: "Company name *",
	        key: "companyName",
	        value: "",
	        renderHtml: true,
	      },
	      {
	        placeholder: "Address *",
	        key: "address",
	        value: "",
	        renderHtml: true,
	      },
	      {
	        placeholder: "Address line 2",
	        key: "addressLine2",
	        value: "",
	      },
	      {
	        placeholder: "Postal code *",
	        key: "postalCode",
	        value: "",
	        float: true,
	        reg: /[\S]/,
	        errorText: "Postal code is required",
	      },
	      {
	        placeholder: "Country *",
	        key: "country",
	        value: "",
	        float: true,
	        itemList: [],
	        renderHtml: true,
	      },
	      // {
	      //   placeholder: "Email *",
	      //   key: "email",
	      //   value: "",
	      //   renderHtml: true,
	      // },
	      // todo
	      // {
	      //   placeholder: "Phone number *",
	      //   key: "phone",
	      //   value: {
	      //     countryCode: "+65",
	      //     phoneNumber: "",
	      //   },
	      //   renderHtml: true,
	      // },
	      {
	        placeholder: "Organization certificate",
	        key: "organizationCertificate",
	        itemList: [],
	        value: "",
	        isUpload: true,
	        renderHtml: true,
	      },
	    ],
	  },
	  snackbarData: {
	    visible: false,
	    content: "",
	  },
	  query: {
	    size: 20,
	    current: 1,
	    status: "",
	  },
	  menuDatas: [
	    {
	      name: "Companies",
	      key: "",
	      active: true,
	      children: [
	        {
	          name: "Active",
	          key: "active",
	        },
	        {
	          name: "Suspended",
	          key: "suspended",
	        },
	        {
	          name: "Closed",
	          key: "closed",
	        },
	      ],
	    },
	  ],
	  activeIndex: "",
	  usersData: {
	    ref: "usersTable",
	    isLoading: false,
	    sort: { name: "", type: "" },
	    tableData: [],
	  },
	  filterRenderData: [
	    { filterKey: "companyName", type: "input", title: "Company name" },
	    {
	      filterKey: "businesses",
	      type: "checkbox",
	      title: "Business(s)",
	      checkboxData: [],
	    },
	    {
	      filterKey: "created",
	      type: "date",
	      title: "Created",
	    },
	  ],
	  multKeys: [],
	  isImgCutShow: false,
	  
	  formDialogAdd2: { //新流程 0712 cy留
	    ref: "formDialogAdd2",
	    visible: false,
	    title: "Add company admin",
	    add: "Add new company",
	    edit: "Edit company",
	    submitBtnTxt: "Add",
	    renderPass: false,
	    slotData: ["firstname", "lastname","email"],
	    isDisabled: true,
	    firstnameErrorObj: {
	      show: false,
	      message: "",
	    },
	    lastnameErrorObj: {
	      show: false,
	      message: "",
	    },
	    emailErrorObj: {
	      show: false,
	      message: "Invalid email address",
	    },
	    dataRender: {
		  photo: "",
		  lastname:"",
		  firstname:"",
	      email: "",
	      phone: ""
	    },
	    dataList: [
	      {
	        placeholder: "logo",
	        key: "photo",
	        value: "",
	        renderHtml: true,
	      },
	      {
	        placeholder: "First name *",
	        key: "firstname",
	        value: "",
	        renderHtml: true,
	      },
	      {
	        placeholder: "Last name *",
	        key: "lastname",
	        value: "",
	        renderHtml: true,
	      },
	      {
	        placeholder: "Email *",
	        key: "email",
	        value: "",
	        renderHtml: true,
			reg: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
			errorText: "Invalid email address",
	      },
	      // todo
	      {
	        placeholder: "Phone number *",
	        key: "phone",
	        value: {
	          countryCode: "+65",
	          phoneNumber: "",
	        },
	        renderHtml: true,
	      }
	      // {
	      //   placeholder: "Organization certificate",
	      //   key: "organizationCertificate",
	      //   itemList: [],
	      //   value: "",
	      //   isUpload: true,
	      //   renderHtml: true,
	      // },
	    ],
	  },
	  snackbarData: {
	    visible: false,
	    content: "",
	  },
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.userId,
    }),
  },
  watch: {
    "formDialogAdd.dataRender.companyName": function (res) {
      if (res.length === 0) {
        this.formDialogAdd.companyNameErrorObj = {
          show: true,
          message: "Company name is required",
        };
      } else if (res.length > 80) {
        this.formDialogAdd.companyNameErrorObj = {
          show: true,
          message: "Character limit of 80",
        };
      } else {
        this.formDialogAdd.companyNameErrorObj = {
          show: false,
          message: "",
        };
      }
    },
	"formDialogAdd1.dataRender.companyName": function (res) {
	  if (res.length === 0) {
	    this.formDialogAdd1.companyNameErrorObj = {
	      show: true,
	      message: "Company name is required",
	    };
	  } else if (res.length > 80) {
	    this.formDialogAdd1.companyNameErrorObj = {
	      show: true,
	      message: "Character limit of 80",
	    };
	  } else {
	    this.formDialogAdd1.companyNameErrorObj = {
	      show: false,
	      message: "",
	    };
	  }
	},
    "formDialogAdd.dataRender.brandName": function (res) {
      if (res.length === 0) {
        this.formDialogAdd.brandNameErrorObj = {
          show: true,
          message: "Brand name is required",
        };
      } else if (res.length > 80) {
        this.formDialogAdd.brandNameErrorObj = {
          show: true,
          message: "Character limit of 80",
        };
      } else {
        this.formDialogAdd.brandNameErrorObj = {
          show: false,
          message: "",
        };
      }
    },
	"formDialogAdd1.dataRender.brandName": function (res) {
	  if (res.length === 0) {
	    this.formDialogAdd1.brandNameErrorObj = {
	      show: true,
	      message: "Brand name is required",
	    };
	  } else if (res.length > 80) {
	    this.formDialogAdd1.brandNameErrorObj = {
	      show: true,
	      message: "Character limit of 80",
	    };
	  } else {
	    this.formDialogAdd1.brandNameErrorObj = {
	      show: false,
	      message: "",
	    };
	  }
	},
    // "formDialogAdd.dataRender.email": function (res) {
    //   let isPass = /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/.test(res);
    //   if (res.length === 0) {
    //     this.formDialogAdd.emailErrorObj = {
    //       show: true,
    //       message: "Email required",
    //     };
    //   } else if (!isPass) {
    //     this.formDialogAdd.emailErrorObj = {
    //       show: true,
    //       message: "Email address is invalid",
    //     };
    //   } else {
    //     this.formDialogAdd.emailErrorObj = {
    //       show: false,
    //       message: "",
    //     };
    //   }
    // },
	"formDialogAdd2.dataRender.email": function (res) {
	  let isPass = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(res);
	  if (res.length === 0) {
	    this.formDialogAdd2.emailErrorObj = {
	      show: true,
	      message: "Email required",
	    };
	  } else if (!isPass) {
	    this.formDialogAdd2.emailErrorObj = {
	      show: true,
	      message: "Email address is invalid",
	    };
	  } else {
	    this.formDialogAdd2.emailErrorObj = {
	      show: false,
	      message: "",
	    };
	  }
	},
    formDialogAdd: {
      deep: true,
      handler(res) {
        this.$nextTick(() => {
          const isRender = res.slotData.every((item) => {
            return (
              this.$refs[item] &&
              !!this.$refs[item].value &&
              !this.$refs[item].error.show
            );
          });
		  
          const isForm = this.$refs.formDialogAdd.valid(); // 表单其他非插槽元素是否验证通过
          let isPhone = false;
          let businessesPass =
            this.$refs.businesses && this.$refs.businesses.value.length > 0;

          if (this.$refs.phone) {
            isPhone =
              this.$refs.phone &&
              !this.$refs.phone.isError.show &&
              !!this.$refs.phone.value.phoneNumber;
          }

          res.renderPass = res.isDisabled =
            isRender &&
            isForm &&
            // isPhone &&
            businessesPass 
        });
      },
    },
	formDialogAdd1: {
	  deep: true,
	  handler(res) {
	    this.$nextTick(() => {
	      const isRender = res.slotData.every((item) => {
	        return (
	          this.$refs[item] &&
	          !!this.$refs[item].value &&
	          !this.$refs[item].error.show
	        );
	      });
	      const isForm = this.$refs.formDialogAdd1.valid(); // 表单其他非插槽元素是否验证通过
	      let isPhone = false;
	      let businessesPass =
	        this.$refs.businesses && this.$refs.businesses.value.length > 0;
	
	      if (this.$refs.phone) {
	        isPhone =
	          this.$refs.phone &&
	          !this.$refs.phone.isError.show &&
	          !!this.$refs.phone.value.phoneNumber;
	      }
	
	      res.renderPass = res.isDisabled =
	        isRender &&
	        isForm &&
	        // isPhone &&
	        businessesPass
			 // &&
	   //      !this.$refs.email.error.show;
	    });
	  },
	},
	formDialogAdd2: {
	  deep: true,
	  handler(res) {
	    this.$nextTick(() => {
	      const isRender = res.slotData.every((item) => {
	        return (
	          this.$refs[item] &&
	          !!this.$refs[item].value &&
	          !this.$refs[item].error.show
	        );
	      });
	      const isForm = this.$refs.formDialogAdd2.valid(); // 表单其他非插槽元素是否验证通过
	      let isPhone = false;
	     
	      if (this.$refs.phone) {
	        isPhone =
	          this.$refs.phone &&
	          !this.$refs.phone.isError.show &&
	          !!this.$refs.phone.value.phoneNumber;
	      }
			// console.log(isRender)
			// console.log(isForm)
			// console.log(isPhone)
	      res.renderPass = res.isDisabled =
	        isRender &&
	        // isForm &&
	        isPhone 
	        // businessesPass
			 // &&
	   //      !this.$refs.email.error.show;
	    });
	  },
	}
  },
  methods: {
    handleRemove() {
      let organizationCertificate = this.formDialogAdd.dataList.find(
        (item) => item.key === "organizationCertificate"
      );
      organizationCertificate.itemList = [];
      this.formDialogAdd.dataRender.organizationCertificate = "";
    },
    handlePreview(file) {
      const needPrint = 1; // 是否需要展示打印按钮
      let reg = /\.([0-9a-z]+)(?:[\?#]|$)/i;
      let fileFormat = file.name.match(reg)[1];

      if (fileFormat === "pdf") {
        window.open(
          `/testFileView?url=${file.url}&name=${file.name}&${needPrint}`
        );
      } else {
        window.open(
          `/testFileView?imgUrl=${file.url}&name=${file.name}${needPrint}`
        );
      }
    },
    uploadFileChange(file, fileList) {
      if (file) {
        let organizationCertificate = this.formDialogAdd.dataList.find(
          (item) => item.key === "organizationCertificate"
        );

        organizationCertificate.isUpload = false;
        organizationCertificate.itemList = [];

        let formData = new FormData();
        formData.append("file", file);
        formData.append("multipartFile", file.raw);
        Ajax.post("/media/public/file/uploadExt", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((res) => {
            if (res.code === "0000") {
              let reg = /\.([0-9a-z]+)(?:[\?#]|$)/i;
              let fileFormat = res.data.filePath.match(reg)[1];
              this.formDialogAdd.dataRender.organizationCertificate = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`;
              organizationCertificate.itemList.push({
                name: file.name,
                url: `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`,
                type: fileFormat,
              });
            }
            if (res.code === "9999") {
              this.handleSnack("Maximum upload size exceeded");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
	uploadFileChange1(file, fileList) {
	  if (file) {
	    let organizationCertificate = this.formDialogAdd1.dataList.find(
	      (item) => item.key === "organizationCertificate"
	    );
	
	    organizationCertificate.isUpload = false;
	    organizationCertificate.itemList = [];
	
	    let formData = new FormData();
	    formData.append("file", file);
	    formData.append("multipartFile", file.raw);
	    Ajax.post("/media/public/file/uploadExt", formData, {
	      headers: { "Content-Type": "multipart/form-data" },
	    })
	      .then((res) => {
	        if (res.code === "0000") {
	          let reg = /\.([0-9a-z]+)(?:[\?#]|$)/i;
	          let fileFormat = res.data.filePath.match(reg)[1];
	          this.formDialogAdd1.dataRender.organizationCertificate = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`;
	          organizationCertificate.itemList.push({
	            name: file.name,
	            url: `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`,
	            type: fileFormat,
	          });
	        }
	        if (res.code === "9999") {
	          this.handleSnack("Maximum upload size exceeded");
	        }
	      })
	      .catch((err) => {
	        console.log(err);
	      });
	  }
	},
    dateFormat(date) {
      if (!date) {
        return "-";
      }
      return moment.utc(date).local().format("DD/MM/YYYY");
    },
    menuSelect(key, item) {
      this.activeIndex = key;
      this.query.status = item.key;
      this.getData();
    },
    getData() {
      this.usersData.isLoading = true;
      Ajax.post("/usermanage/company/pageList", this.query)
        .then((res) => {
          this.usersData.isLoading = false;
          let { records, total } = res.data;
          this.usersData.tableData = records;
          this.usersData.totalNum = total;
        })
        .catch((err) => {
          this.usersData.isLoading = false;
        });
    },
    _onSortChange(sort) {
      this.usersData.sort = sort;
      this.query.sortName = sort.name;
      this.query.sortType = sort.type;
      this.getData();
    },
    _pageChange(val) {
      this.query.current = val;
      this.getData();
    },
    _pageSizeChange(val) {
      this.query.size = val;
      this.getData();
    },
    _filterCallback(data) {
      console.log("用户选的筛选条件 === ", data);
      let obj = {};
      if (data.length == 0) {
        this.query = {
          size: 10,
          current: 1,
          status: "",
        };
      }

      data.filter((i) => {
        obj[i.filterKey] = i.filterVal;
      });
		let businessesString=''
		if(obj.businesses){
			obj.businesses.forEach((item,index)=>{
				if(index==0){
					businessesString+=(item)
				}else{
					businessesString+=(','+item)
				}
				
			})
		}
		obj.businesses=businessesString
      this.query = {
        size: 20,
        current: 1,
        status: "",
      };
      this.query = Object.assign(obj, this.query);
      this.getData();
    },
    _uploadCallback(formData, fileData) {
      formData.append("multipartFile", fileData.raw);
      Ajax.post("/media/public/file/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          this.formDialogAdd.dataRender.logo = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`;
		    this.formDialogAdd1.dataRender.logo = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`;
			  this.formDialogAdd2.dataRender.logo = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`;
          this.isImgCutShow = false;
        })
        .catch(() => {
          this.isImgCutShow = false;
        });
    },
	_uploadCallback1(formData, fileData) {
	  formData.append("multipartFile", fileData.raw);
	  Ajax.post("/media/public/file/upload", formData, {
	    headers: { "Content-Type": "multipart/form-data" },
	  })
	    .then((res) => {
	     //  this.formDialogAdd.dataRender.logo = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`;
		    // this.formDialogAdd1.dataRender.logo = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`;
			  this.formDialogAdd2.dataRender.photo = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`;
	      this.isImgCutShow1 = false;
	    })
	    .catch(() => {
	      this.isImgCutShow = false;
	    });
	},
    handleSnack(content) {
      this.snackbarData.content = content;
      this.snackbarData.visible = true;
    },
    fillData() {
      let obj = {};
      this.$refs.formDialogAdd.dataExceptRender.forEach((item) => {
        obj[item.key] = item.value;
      });
      let formData = Object.assign({}, obj, this.formDialogAdd.dataRender);

      let { phoneNumber, countryCode } = this.formDialogAdd.dataRender.phone;
      formData.phoneNumber = phoneNumber;
      formData.countryCode = countryCode;
	  formData.creater=this.userId
      return formData;
    },
    openDialogAdd(row) {
      if (row == null) {
		  this.formDialogAdd1.visible = true;
        this.formDialogAdd1.title = this.formDialogAdd1.add;
        this.formDialogAdd1.submitBtnTxt = "Next";
        this.formDialogAdd1.dataList.forEach((i) => {
          i.value = "";
          if (i.key === "organizationCertificate") {
            i.itemList = [];
          }
        });

        this.formDialogAdd1.dataRender = {
          logo: "",
          brandName: "",
          businesses: [],
          address: "",
          companyName: "",
          country: "",
          email: "",
          organizationCertificate: "",
        };
      } else {
		 
        console.log(row);
        this.formDialogAdd.title = this.formDialogAdd.edit;
        this.formDialogAdd.submitBtnTxt = "Save";
        this.formDialogAdd.id = row.id;
		 this.formDialogAdd.companyUserid = row.userId;
        // this.formDialogAdd.renderPass = false;
        this.formDialogAdd.dataList.forEach((i) => {
          i.value = row[i.key];
          if (
            i.key === "organizationCertificate"
          ) {
            if (row.organizationCertificate) {
              i.itemList = [];
              let reg = /\.([0-9a-z]+)(?:[\?#]|$)/i;
              let fileName = row.organizationCertificate.split("/");
              let fileFormat = fileName[fileName.length - 1].match(reg)[1];
              i.itemList.push({
                name: fileName[fileName.length - 1],
                url: row.organizationCertificate,
                type: fileFormat,
              });
            } else {
              i.itemList = [];
            }
          }
        });

        Object.keys(this.formDialogAdd.dataRender).forEach((item) => {
          this.formDialogAdd.dataRender[item] = row[item];
        });

        this.formDialogAdd.dataRender.phone = {
          countryCode: row["countryCode"],
          phoneNumber: row["phoneNumber"],
        };

        this.formDialogAdd.dataRender.businesses = row.businessArray;
		 this.formDialogAdd.visible = true;
      }
      
    },
    handleCancelForm() {
      this.formDialogAdd.visible = false;
    },
	handleCancelForm1() {
	  this.formDialogAdd1.visible = false;
	},
	handleCancelForm2() {
	  this.formDialogAdd2.visible = false;
	},
    handleAddForm(res) {
      if (this.formDialogAdd.title === this.formDialogAdd.edit) {
        this.handleEditSubmit();
      } else {
        this.handleAddSubmit();
      }
    },
	handleAddForm1(res) {
		let obj = {};
		this.$refs.formDialogAdd1.dataExceptRender.forEach((item) => {
		  obj[item.key] = item.value;
		});
		let formData = Object.assign({}, obj, this.formDialogAdd1.dataRender);
		formData.creater=this.userId
		this.firstData=formData
		this.formDialogAdd1.visible = false
		this.formDialogAdd2.visible = true
		this.formDialogAdd2.dataRender = {
		 firstname:"",
		 lastname:"",
		  email: "",
		  phone: {
		    countryCode: "+65",
		    phoneNumber: "",
		  }
		};
	  // if (this.formDialogAdd.title === this.formDialogAdd.edit) {
	  //   this.handleEditSubmit();
	  // } else {
	  //   this.handleAddSubmit();
	  // }
	},
	handleAddForm2(res) {
		   this.handleAddSubmit();
		// this.firstData=this.fillData()
		// this.formDialogAdd1.visible = false
		// this.formDialogAdd2.visible = false
	  // if (this.formDialogAdd.title === this.formDialogAdd.edit) {
	  //   this.handleEditSubmit();
	  // } else {
	 
	  // }
	},
	fillData1() {
	  let obj = {};
	  // this.$refs.formDialogAdd2.dataExceptRender.forEach((item) => {
	  //   obj[item.key] = item.value;
	  // });
	  let formData = Object.assign({}, obj, this.formDialogAdd2.dataRender);
	
	  let { phoneNumber, countryCode } = this.formDialogAdd2.dataRender.phone;
	  formData.phoneNumber = phoneNumber;
	  formData.countryCode = countryCode;
	  formData.creater=this.userId
	  return formData;
	},
    handleAddSubmit() {
		let params = {};
		params = Object.assign({},this.firstData,this.fillData1(),{
		  userId: this.userId,
		});
		if(params.photo){
			params.avatar = params.photo
		}
		if(params.firstname){
			params.firstName = params.firstname
		}
		if(params.lastname){
			params.lastName = params.lastname
		}
		console.log(params)
		// return false
      Ajax.post("/usermanage/company/saveCompany", params).then(
        (res) => {
          if (res.code === "0000") {
            this.getData();
            this.handleSnack("Company has been added");
            this.formDialogAdd2.visible = false;
          } else {
            if (res.message === "Email already exists") {
              this.formDialogAdd2.emailErrorObj = {
                show: true,
                message: res.message,
              };
            }
            
            if (res.message === "Brand name already exists") {
              this.formDialogAdd2.brandNameErrorObj = {
                show: true,
                message: res.message,
              };
            }
            
            if (res.message === "Company name already exists") {
              this.formDialogAdd2.companyNameErrorObj = {
                show: true,
                message: res.message,
              };
            }
          }
        }
      );
    },
    handleEditSubmit() {
      let params = {};

      params = Object.assign({}, this.fillData(), {
        userId: this.formDialogAdd.companyUserid,
        id: this.formDialogAdd.id,
      });

      Ajax.post("/usermanage/company/updateCompany", params).then((res) => {
        if (res.code === "0000") {
          this.getData();
          this.handleSnack("Company has been edited");
          this.formDialogAdd.visible = false;
        } else {
            if (res.message === "Email already exists") {
              this.formDialogAdd.emailErrorObj = {
                show: true,
                message: res.message,
              };
            }
            
            if (res.message === "Brand name already exists") {
              this.formDialogAdd.brandNameErrorObj = {
                show: true,
                message: res.message,
              };
            }
            
            if (res.message === "Company name already exists") {
              this.formDialogAdd.companyNameErrorObj = {
                show: true,
                message: res.message,
              };
            }
          }
      });
    },
    toCompanyInfo(row) {
      this.$router.push({
        name: "companyInfo",
        query: { id: row.row.id },
      });
    },
    // reactive弹窗
    handleReactive(row) {
      this.$confirm(
        "Once reactivated, the company will be able to access the apps.",
        "Reactivate company account ?",
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Reactivate",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false,
        }
      )
        .then(() => {
          Ajax.post("/usermanage/company/updateCompanyStatus", {
            status: "active",
            id: row.id,
          }).then((res) => {
            if (res.code === "0000") {
              this.getData();
              this.handleSnack("Company account has been reactivated");
            }
          });
        })
        .catch(() => {});
    },
    // resotre弹窗
    handleRestore(row) {
	  this.$confirm(
	    "Once restored, the company will be able to log in the platform and recover the data.",
	    "Restore company account ?",
	    {
	      cancelButtonText: "Cancel",
	      confirmButtonText: "Restore",
	      cancelButtonClass: "gg_cancel_button",
	      confirmButtonClass: "gg_submit_button",
	      customClass: "gg_confirm",
        showClose: false,
	    }
	  )
	    .then(() => {
	      Ajax.post("/usermanage/company/updateCompanyStatus", {
	        status: "active",
	        id: row.id,
	      }).then((res) => {
	        if (res.code === "0000") {
	          this.getData();
	          this.handleSnack("Company account has been restored");
	        }
	      });
	    })
	    .catch(() => {});
	},
  // suspend弹窗
    handleSuspend(row) {
      this.$confirm(
        "Once suspended, the company will not be able to access the apps.",
        "Suspend company account ?",
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Suspend",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false, 
        }
      )
        .then(() => {
          Ajax.post("/usermanage/company/updateCompanyStatus", {
            status: "suspended",
            id: row.id,
          }).then((res) => {
            if (res.code === "0000") {
              this.getData();
              this.handleSnack("Company account has been suspended");
            }
          });
        })
        .catch(() => {});
    },
    // close弹窗
    handleClose(row) {
      this.$confirm(
        "Once closed, the company will not be able to log in the platform.",
        "Close company account ?",
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Close",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false,
        }
      )
        .then(() => {
          Ajax.post("/usermanage/company/updateCompanyStatus", {
            status: "closed",
            id: row.id,
          }).then((res) => {
            if (res.code === "0000") {
              this.getData();
              this.handleSnack("Company account has been closed");
            }
          });
        })
        .catch(() => {});
    },
    getSettingList(dataLevel, dataType) {
      let params = {
        dataLevel: dataLevel,
        dataType: dataType,
        userId: this.userId,
      };

      Ajax.post("/usermanage/setting/getList", params).then((res) => {
        let arr = [];
        res.data.forEach((item1) => {
          if (dataType !== "country") {
            this.filterRenderData.forEach((item) => {
              let obj = {};
              if (item1.dataType === item.filterKey) {
                obj = {
                  key: item1.value,
                  value: item1.value,
                };
                arr.push(obj);
              }
            });

            this.filterRenderData.find(
              (item) => item.filterKey === dataType
            ).checkboxData = arr;
          }
          this.formDialogAdd.dataList.forEach((item2) => {
            if (item1.dataType === item2.key) {
              item2.itemList.push(item1.value);
            }
          });
		  this.formDialogAdd1.dataList.forEach((item2) => {
		    if (item1.dataType === item2.key) {
		      item2.itemList.push(item1.value);
		    }
		  });
        });
      });
    },
  },
  created() {
    this.getData();
    this.getSettingList("companies", "businesses");
    this.getSettingList("general", "country");
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
</style>